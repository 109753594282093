import Vue from "vue";
import App from "./App.vue";
import privateRouter from "./router";
import publicRouter from "./publicRouter";
import store from "./store";
import vuetify from "./plugins/vuetify";
import commonMixin from "@/mixins/common";
import VueClipboard from 'vue-clipboard2';

import axios from "axios";
import VueZoomer from "vue-zoomer";
import VueCryptojs from "vue-cryptojs";
import moment from 'moment'
import moment1 from 'moment-timezone'
import VueMeta from 'vue-meta'
import * as VueGoogleMaps from "vue2-google-maps" 
import VueI18n from 'vue-i18n';
import enLocale from './locales/en';
import esLocale from './locales/es';
moment1.tz.setDefault('America/New_York')
var AddToCalendar = require('vue-add-to-calendar');

Vue.use(AddToCalendar);
Vue.use(VueI18n);
Vue.use(VueMeta);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueGoogleMaps, {
  load: {
    key: "GOOGLE MAP API KEY GOES HERE",
    libraries: "places"
  }
});
Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const host = window.location.host;
const parts = host.split('.');
const domainLength = 2; // route1.example.com => domain length = 3

const router = () => {
  let routes;
  let domains = ['devharlemwizardsinabox','harlemwizardsinabox', 'localhost:8080','localhost:8081']
  if (parts.length >= domainLength && domains.includes(parts[1])) {
    console.log('Public Route: ', parts );
    routes = publicRouter;
  } else {
    console.log('Private Route: ',parts);
    routes = privateRouter;
  }
  return routes;
};
const messages = {
  en: enLocale,
  es: esLocale,
  // Add more languages if needed
};
const i18n = new VueI18n({
  locale: 'es', // set the default locale
  messages,
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM-DD-YYYY')
  }
});

Vue.use(VueZoomer);
Vue.use(VueCryptojs);
Vue.use(VueMeta, {
  keyName: "head",
});
/** Register global mixin **/
Vue.mixin(commonMixin);


new Vue({
  router: router(),
  store,
  vuetify,
    i18n,
  render: (h) => h(App),
}).$mount("#app");
